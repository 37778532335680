'use strict'

module.exports = async (settings) => {
  const getData = async () => {
    return new Promise(
      (resolve) =>
        settings.flixPost({
          url: 'holidays/get',
          data: {
            user: settings.user,
            filter: settings.ID
          },
          callback: (ret) => { resolve(ret.data) }
        })
    )
  }
  const dates = await getData()
  return dates[1]
}
