<template>
 <div>
  <b-flix-loader v-if="!loaded"></b-flix-loader>
  <div v-else>
    <div v-if="!data">
      <b-flix-p>{{ $tc('message.noEntry', 2) }}</b-flix-p></div>
    <div v-else>
      <div v-for="(date, index) in data" :key="date.ID">
        <div class="flex flex-center flex-start flex-gap-10">
          <b-flix-p>
            <b>{{ date.title }}</b>, {{ getDate(date) }}
          </b-flix-p>
          <b-flix-p f>
            <a v-if="open !== index" href="#" class="flix-html-a flix-text-danger" @click.prevent="open = index">{{ $t('message.delete') }}</a>
            <div class="flex flex-gap-5" v-if="open === index">
              <div class="flix-text-danger">{{ $t('message.delete') }}?</div>
              <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="setDelete(index)">{{ $t('message.yes') }}</a>
              <a href="#" class="flix-btn flix-btn-default flix-btn-xs" @click.prevent="open = false">{{ $t('message.no') }}</a>
            </div>
          </b-flix-p>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
const tableList = require('./tableList.js')
const holidaysJS = require('./holidays.js')
export default {
  name: 'holidayTableList',
  components: {},
  props: {
    id: {
      type: String,
      default () { return '' }
    }
  },
  data () {
    return {
      loaded: false,
      data: false,
      open: false
    }
  },
  computed: {

  },
  mounted () {
    this.getHolidays()
  },
  methods: {
    async setDelete (id) {
      const newData = JSON.parse(JSON.stringify(this.data))
      newData.splice(id, 1)
      const handleData = {
        assistentsID: Object.keys(this.$store.state.assistents.assistentsByID),
        oldData: this.data,
        newData: newData,
        flixPost: this.$flix_post,
        user: this.$store.getters.user.md5_id,
        filter: this.id || '*'
      }

      await holidaysJS(handleData)
      this.$emit('save')
    },
    getDate (date) {
      const begin = this.$beautifyDate(date)
      return begin
    },
    async getHolidays () {
      this.data = await tableList({ ID: this.id, flixPost: this.$flix_post, user: this.$getUserVariables().user.md5_id })
      this.loaded = true
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
