'use strict'

module.exports = async (settings) => {
  let currentDate = ''
  const generateHashes = (data) => {
    const hash = {}
    for (let i = 0; i < data.length; i++) {
      hash[data[i].ID] = JSON.stringify(data[i])
    }
    return hash
  }
  const checkNewEntries = async (data) => {
    const hash = {}
    for (let i = 0; i < data.length; i++) {
      if (typeof data[i].ID === 'undefined') {
        data[i].ID = await sendData('holidays/save', data[i])
        currentDate = data[i].begin
        hash[data[i].ID] = JSON.stringify(data[i])
      } else {
        hash[data[i].ID] = JSON.stringify(data[i])
      }
    }
    return hash
  }

  const checkChanged = async (oldData, newData) => {
    for (let i = 0; i < Object.keys(oldData).length; i++) {
      if (!Object.keys(newData).includes(Object.keys(oldData)[i])) {
        const ID = await moveAll(JSON.parse(oldData[Object.keys(oldData)[i]]), JSON.parse(oldData[Object.keys(oldData)[i]]), JSON.parse(Object.values(oldData)[i]).to)
        await sendData('holidays/delete', ID || Object.keys(oldData)[i], settings.filter)
        currentDate = JSON.parse(oldData[Object.keys(oldData)[i]]).begin
      } else if (oldData[Object.keys(oldData)[i]] !== newData[Object.keys(oldData)[i]]) {
        const ID = await moveAll(JSON.parse(oldData[Object.keys(oldData)[i]]), JSON.parse(newData[Object.keys(oldData)[i]]), JSON.parse(Object.values(oldData)[i]).to)

        const nD = JSON.parse(newData[Object.keys(oldData)[i]])
        if (ID) {
          nD.ID = ID
        }
        newData[Object.keys(oldData)[i]] = JSON.stringify(nD)
        await sendData('holidays/save', nD, settings.filter)
        currentDate = JSON.parse(newData[Object.keys(oldData)[i]]).begin
      }
    }
  }

  const moveAll = async (data, newData, target) => {
    if (target !== '*') {
      return false
    }

    if (settings.filter === '*') {
      return false
    }

    await sendData('holidays/delete', newData.ID, target)
    const ID = await sendData('holidays/save', Object.assign(newData, { ID: undefined, to: settings.filter }), settings.filter)
    const oldFilter = settings.filter
    settings.assistentsID.forEach(async (id) => {
      if (id.toString() === oldFilter.toString()) {
        console.log('same ' + id)
        return true
      }
      const submit = Object.assign(newData, { ID: undefined, to: id })
      settings.filter = id
      await sendData('holidays/save', JSON.parse(JSON.stringify(submit)), id)
    })
    settings.filter = oldFilter
    return ID
  }

  const parseNewData = (newData) => {
    const ret = []
    Object.values(newData).forEach(n => { ret.push(JSON.parse(n)) })
    return ret
  }

  const sendData = async (url, data, to) => {
    let saveData = ''
    switch (url) {
      case 'holidays/save':
        saveData = {
          data: Object.assign(data, { filter: settings.filter, status: 'active' }),
          filter: to || settings.filter,
          user: settings.user
        }
        break
      case 'holidays/delete':
        saveData = {
          ID: data,
          filter: to || settings.filter,
          user: settings.user
        }
        break
    }
    return new Promise(
      (resolve) =>
        settings.flixPost({
          url: url,
          data: saveData,
          callback: (ret) => { resolve(ret.data[2]) }
        })
    )
  }

  const oldData = generateHashes(settings.oldData)
  const newData = await checkNewEntries(settings.newData)
  await checkChanged(oldData, newData)

  return [currentDate, parseNewData(newData)]
}
